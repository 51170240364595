import React from "react"
import { Formik, Form, useField } from "formik"
import * as Yup from "yup"
import Reaptcha from "reaptcha"
import moment from "moment"
import "moment-timezone"

import { ContactOptions, ProductOptions } from "../UserForm/Dropdowns"
import { navigate } from "gatsby"

class UserForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submissionMessage: "" }
  }

  render() {
    const TextInput = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <input className="uk-input" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const TextArea = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <textarea className="uk-textarea" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const Select = ({ label, required, ...props }) => {
      const [field, meta] = useField(props)
      return (
        <div className="uk-margin">
          <label
            className="uk-text-bold uk-display-block uk-margin-small"
            htmlFor={props.id || props.name}
          >
            {label} {required && <abbr title="required">*</abbr>}
          </label>
          <select className="uk-select" {...field} {...props} />
          {meta.touched && meta.error ? (
            <div className="uk-alert uk-alert-danger uk-margin-remove-top">
              {meta.error}
            </div>
          ) : null}
        </div>
      )
    }

    const formattedLocalDateTime = moment()
      .tz("Australia/Melbourne")
      .format("DD/MM/YYYY HH:mm:ss")

    const pathNameWithoutSlash = window.location.pathname.replaceAll("/", "")

    const form = (
      <Formik
        initialValues={{
          name: "",
          phone: "",
          email: "",
          contactType: "",
          productType: "",
          message: "",
          postCode: "",
          recaptcha: "",
          dateAdded: `${formattedLocalDateTime}`,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Required"),
          phone: Yup.string().matches(
            /^[0-9]{10}|[0-9]{9}$/,
            "Must be exactly 9 or 10 digits e.g. 0432112112 or 039234564"
          ),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
          message: Yup.string(),
          postCode: Yup.string().required("Required"),
          recaptcha: Yup.string().required("Required"),
        })}
        onSubmit={async (values, actions) => {
          actions.setSubmitting(true)
          this.setState({ submissionMessage: null })
          fetch(process.env.GATSBY_FETCH_URL_CONTACT, {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify(values),
          })
            .then((response) => {
              if (response.status === 200) {
                navigate(`/thank-you?source=${pathNameWithoutSlash}`)
              } else {
                this.setState({
                  submissionMessage:
                    "There was an error with your submission. Please refresh and try again.",
                })
              }
            })
            .catch((error) => {
              this.setState({
                submissionMessage:
                  "There was an error with the form submission. Please refresh this page and try again.",
              })
            })
            .finally(() => actions.setSubmitting(false))
        }}
      >
        {({ isSubmitting, setFieldValue, errors, touched, status }) => (
          <div
            className={
              isSubmitting ? "form-container spinner" : "form-container"
            }
          >
            <Form className="uk-grid-small uk-grid-row-medium uk-grid-column-medium uk-grid warranty-form">
              <div className="uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Full Name"
                  name="name"
                  type="text"
                  placeholder="Full name"
                  required="true"
                />
              </div>
              <div className="uk-width-1-1@s uk-margin-small-top">
                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="jane@example.com"
                  required="true"
                />
              </div>
              <div className="uk-width-1-1@s uk-margin-small-top">
                <TextInput
                  label="Contact Number"
                  name="phone"
                  type="text"
                  placeholder="0412345678 or 039901234"
                  maxLength="10"
                />
              </div>
              <div className="uk-width-1-1@s uk-margin-small-top">
                <Select
                  label="Contact reason"
                  name="contactType"
                  id="contactType"
                >
                  <ContactOptions />
                </Select>
                <p className="uk-text-small uk-margin-bottom">
                  Looking for an installer? Visit our{" "}
                  <a href="/request-an-installer">
                    <strong>request an installer form</strong>
                  </a>
                  .
                </p>
              </div>
              <div className="uk-width-1-1@s uk-margin-small-top">
                <Select
                  label="Product Type"
                  name="productType"
                  id="productType"
                >
                  <ProductOptions />
                </Select>
              </div>
              <div className="uk-width-1-1@s uk-margin-small-top">
                <TextArea
                  label="Message"
                  name="message"
                  type="message"
                  placeholder="Enter your message"
                  rows="3"
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Post code"
                  name="postCode"
                  type="text"
                  placeholder="Post code"
                  required="true"
                />
                <p className="uk-text-small uk-margin-bottom">
                  Your postcode helps us respond faster - we personalise our
                  Service based on your location
                </p>
              </div>
              <div className="uk-width-auto@s uk-margin-small-top">
                <label
                  className="uk-text-bold uk-display-block uk-margin-small"
                  htmlFor="recaptcha"
                >
                  Verification
                </label>
                <Reaptcha
                  sitekey="6LdcWsYUAAAAAK4RDlHR3k8m2hqmU1bENl1GEV_E"
                  theme="light"
                  onVerify={(token) => {
                    setFieldValue("recaptcha", token)
                  }}
                />
                {errors.recaptcha && touched.recaptcha && (
                  <div className="uk-alert uk-alert-danger uk-margin-remove-top">
                    {errors.recaptcha}
                  </div>
                )}
              </div>
              <div className="uk-hidden uk-width-1-1@s uk-grid-margin">
                <TextInput
                  label="Date submitted (Not required to complete - automated)"
                  name="DateAdded"
                  id="DateAdded"
                  type="hidden"
                  className="uk-input"
                  value={formattedLocalDateTime}
                />
              </div>
              <div className="uk-width-1-1@s uk-grid-margin">
                <button
                  type="submit"
                  className="uk-button uk-button-primary uk-border-pill"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
                <span className="uk-margin-left custom-error">
                  {Object.keys(errors).length ? (
                    <div className="uk-alert-danger uk-margin-top">
                      <span
                        uk-icon="warning"
                        className="uk-padding-small"
                      ></span>{" "}
                      Please fill out the required fields
                    </div>
                  ) : null}
                  {this.state.submissionMessage ? (
                    <div className="uk-alert uk-margin-top">
                      {this.state.submissionMessage}
                    </div>
                  ) : null}
                </span>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )
    return form
  }
}

export default UserForm
